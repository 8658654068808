import { createRouter, createWebHistory } from "vue-router";
import { useAuthUserStore } from "@/store/authUser";

//Usuaris
import UsuarisLlistaView from "../usuaris/views/UsuarisLlistaView.vue";
import LoginView from "../usuaris/views/LoginView.vue";
import HomeView from "../usuaris/views/HomeView.vue";
import UsuarisFitxaView from "../usuaris/views/UsuarisFitxaView";
import FitxarView from "../usuaris/views/FitxarView.vue";

// Clients
import ClientsLlistaView from "../clients/views/ClientsLlistaView.vue"
import ClientComandesView from "../clients/views/ClientComandaView.vue"
import ClientAlbaransView from "../clients/views/ClientAlbaransView.vue"
import ClientFacturesView from "../clients/views/ClientFacturesView.vue"
import ClientTipusView from "../clients/views/ClientTipusView.vue"
import ClientFitxaView from "../clients/views/ClientFitxaView.vue"
import FitxaComandaClient from "../clients/views/FitxaComandaClientView.vue"
import FitxaAlbaraClient from "../clients/views/FitxaAlbaraClientView.vue"
import FitxaFacturaClient from "../clients/views/FitxaFacturaClientView.vue"
import FitxaTipusClient from "../clients/views/FitxaTipusClientView.vue"


// Productes
import ProductesLlistaView from "../productes/views/ProductesLlistaView.vue"
import FitxaProducteView from "../productes/views/FitxaProducteView.vue"

// Tasques
import RegistreTasquesView from "../tasques/views/ResgistreTasquesView.vue"

// Genèric
import CarregaPaginaAjax from '../views_generics/CarregaPaginaAjax.vue'
  

const routes = [
 
  {
    path: "/carregaPagina",
    name: "carregaPaginaAjax",
    component: CarregaPaginaAjax,
    meta: { requireAuth: true },
  },

  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },

  {
    path: "/",
    name: "homeView",
    component: HomeView,
    meta: { requireAuth: true },
  },

  {
    path: "/fitxar",
    name: "fitxar",
    component: FitxarView,
    meta: { requireAuth: true },
  },

    //Rutes usuaris

  {
    path: "/usuaris/:id",
    name: "usuarisFitxa",
    component: UsuarisFitxaView,
    meta: { requireAuth: true },
  },

  {
    path: "/usuaris",
    name: "usuarisllista",
    component: UsuarisLlistaView,
    meta: { requireAuth: true },
  },

    //Rutes clients

  {
    path: "/clients",
    name: "clientsllista",
    component: ClientsLlistaView,
    meta: { requireAuth: true },
  },

  {
    path: "/client/:id",
    name: "clientFitxa",
    component: ClientFitxaView,
    meta: { requireAuth: true },
  },

  {
    path: "/comandesclient",
    name: "comandesclient",
    component: ClientComandesView,
    meta: { requireAuth: true },
  },

  {
    path: "/comanda/:id",
    name: "comandaFitxa",
    component: FitxaComandaClient,
    meta: { requireAuth: true },
  },

  {
    path: "/albaransclient",
    name: "albaransclient",
    component: ClientAlbaransView,
    meta: { requireAuth: true },
  },

  {
    path: "/albara/:id",
    name: "albaraFitxa",
    component: FitxaAlbaraClient,
    meta: { requireAuth: true },
  },

  {
    path: "/facturesclient",
    name: "facturesclient",
    component: ClientFacturesView,
    meta: { requireAuth: true },
  },

  {
    path: "/factura/:id",
    name: "facturaFitxa",
    component: FitxaFacturaClient,
    meta: { requireAuth: true },
  },

  {
    path: "/tipusclient",
    name: "tipusclient",
    component: ClientTipusView,
    meta: { requireAuth: true },
  },

  {
    path: "/tipus/:id",
    name: "tipusFitxa",
    component: FitxaTipusClient,
    meta: { requireAuth: true },
  },


  // Rutes ptoductes
  
  {
    path: "/productes",
    name: "productesllista",
    component: ProductesLlistaView,
    meta: { requireAuth: true },
  },

  {
    path: "/producte/:id",
    name: "producteFitxa",
    component: FitxaProducteView,
    meta: { requireAuth: true },
  },

  // Rutes tasques
  {
    path: "/tasques",
    name: "tasquesllista",
    component: RegistreTasquesView,
    meta: { requireAuth: true },
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass:'active'
});

router.beforeEach((to, from, next) => {
  const authUserStore = useAuthUserStore();
  if (to.meta.requireAuth && !authUserStore.isLoggedIn()) {
    authUserStore.returnUrl = to.fullPath;
    next({ name: "Login" });
  } else next();
});

export default router;
