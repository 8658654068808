<template>
  <div class="card btGruiSucces mb-3">
    <div class="card-header info-tasques">
      {{ formatDate(useStore.dataInici) }}
    </div>
    <div class="card-body">
      <div v-if="useStore.realitzacions.length > 0">
        <div
          v-for="realitzacio in useStore.realitzacions"
          :key="realitzacio.id"
        >
          <div v-if="realitzacio.end">
            <div
              class="container-tasca"
              :style="{
                backgroundColor: realitzacio.color,
                height: `${realitzacio.durada * 2}px`,
              }"
            >
              <div class="container-info-tasca">
                <div class="horaInici-horaFi">
                  <span>{{ realitzacio.start.substr(10, 6) }}</span>
                  <span class="guionet">-</span>
                  <span v-if="realitzacio.end">{{
                    realitzacio.end.substr(10, 6)
                  }}</span>
                  <span v-else>Sense data fi</span>
                </div>
                <div class="titol_tasca">
                  <span class="titol-tasca">{{ realitzacio.title }}</span>
                </div>
                
                <div class="total_temps_tasca">
                  <span v-if="realitzacio.durada">{{
                    convertirMinuts(Number(realitzacio.durada))
                  }}</span>
                  <span v-else>En curs</span>
                </div>
              </div>
              <div>
                <input
                  class="form-control input"
                  type="text"
                  v-model="realitzacio.comentarisRealitzacio"
                  @blur="
                    useStore.postAfegirComentari(
                      realitzacio.id.slice(2),
                      realitzacio.comentarisRealitzacio)"
                />
              </div>
            </div>
            <div
              class="espai-blanc"
              :style="{ marginBottom: `${crearEspaisEnBlanc()}px` }"
            ></div>
          </div>
        </div>
      </div>
      <div v-else>
        <span>No hi ha tasques amb les dates seleccionades</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { formatDate, convertirMinuts } from "@/variablesGlobals/variables";
import { useTasquesStore } from "../store/useTasques";

const useStore = useTasquesStore();

  useStore.dataInici = new Date();
  useStore.dataFi = new Date();

//FUNCIÓ PER CREAR ESPAIS EN BLANC ENTRE TASQUES REALITZACIÓ. (NO FUNCIONA)

const crearEspaisEnBlanc = () => {
  const inici = ref();
  const final = ref();
  for (let i = 0; i < useStore.realitzacions.length - 2; i++) {
   
      final.value = Date.parse(useStore.realitzacions[i].end);

      inici.value = Date.parse(useStore.realitzacions[i + 1].start);
      console.log('final ' + final.value)
      console.log('inici' + inici.value)
      return ((inici.value - final.value));
    
  }
};
</script>

<style scoped>
.container-tasca {
  display: flex;
  flex-direction: column;
  border: 1px solid #555;
  border-radius: 5px;
  min-height: 75px;
  margin: 3px 0;
}

.container-info-tasca {
  display: flex;
  justify-content: space-between;
}

.horaInici-horaFi {
  padding: 5px 10px;
  display: flex;
  flex-wrap: wrap;
  width: 25%;
}

.guionet {
  padding: 0 5px;
}

.titol_tasca {
  width: 65%;
  padding: 5px;
}

.total_temps_tasca {
  width: 10%;
  padding: 5px 10px;
  text-align: end;
}

.input {
  height: 25px;
  width: 75%;
  margin: 10px;
}

@media (max-width: 768px) {
  .container-tasca {
    min-height: 95px;
  }
  .total_temps_tasca {
    width: 35%;
    padding: 5px 10px;
    text-align: end;
  }

  .guionet {
    display: none;
  }

  .input {
    height: 25px;
    width: 90%;
  }

  .horaInici-horaFi {
    flex-direction: column;
  }
}

@media (max-width: 320px) {
  .container-tasca {
    min-height: 140px;
  }
}
</style>
