<template>
  <div v-show="authUserStore.isLoggedIn()" id="userBox">
    <UserBox />
  </div>
  <div class="main">
    <SideBar />
      
      <router-view  />            

      <div v-if="useAuthUserStore.getError">
        <ErrorGeneric />
      </div>
    
  </div>

  <div id="historic" style="display:none"></div>
    <div id="dadesTraspas" style="display:none"></div>
    <div id="finestra1" class="positionAuto"></div>
    <div id="finestra2" class="positionAuto"></div>
    <div id="finestra3" class="positionAuto"></div>
    <div id="imprimiEtiqueta"></div>

</template>

<script setup>

import SideBar from "../src/components_generics/SideBar.vue";
import UserBox from "../src/components_generics/UserBox.vue"
import ErrorGeneric from "./components_generics/ErrorGeneric.vue"
import {useAuthUserStore }from '@/store/authUser'

const authUserStore = useAuthUserStore();
</script>

<style scoped>

</style>
