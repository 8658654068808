<template>
  <div>
    <div class="navbar">
      <div class="navbar navbar-expand-lg menuVistes">
        <ul class="nav justify-content-start">
          <li class="nav-item">
            <a
              class="nav-link nodisabled"
              @click="useStore.filtrePrioritat('0')"
            >
              <span>Prioritat 0</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link nodisabled"
              @click="useStore.filtrePrioritat('1')"
            >
              <span>Prioritat 1</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link nodisabled"
              @click="useStore.filtrePrioritat('2')"
            >
               <span>Prioritat 2</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link nodisabled"
              @click="useStore.filtrePrioritat('3')"
            >
               <span>Prioritat 3</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link nodisabled"
              @click="useStore.filtrePrioritatTasca = '*'"
            >
               <span>Totes</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="useStore.llistatTasquesFiltrat().length > 0">
      <div
        v-for="tasca in useStore.llistatTasquesFiltrat()"
        :key="tasca.idTasca"
      >
        <div class="container-tasca">
          <div class="titol-tasca">
            <span>{{ tasca.titolTasca }}</span>
          </div>
          <div class="estat-tasca" :style="{backgroundColor : tasca.colorEstatTasca}">
            {{ tasca.nomEstatTasca }}
          </div>
         
          <div  >
            <i 
              @click="activarTasca(tasca.idTasca, tasca.titolTasca)"
              class="bi bi-play-btn me-3"
              style="color: green"
            ></i>
            
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <span>No hi han tasques per aquest projecte</span>
    </div>
  </div>
</template>

<script setup>
import { useTasquesStore } from "../store/useTasques";

const useStore = useTasquesStore();

//FUNCIÓ PER MOSTRAR I INIZIALITZAR  EL CRONOMETRE, GUARDAR EL TITOL DE LA TASCA A STORE I CRIDAR A FUNCIO STARSTOP

const activarTasca = (id ,titolTasca) => {
  useStore.mostrarCronometreTasca =! useStore.mostrarCronometreTasca
  useStore.titolTascaCronometre = titolTasca
  useStore.cronometrar = true;
  useStore.fetchStarStop(id)

};
</script>

<style scoped>

span{
  color: #333;
}

.container-tasca {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid #8181823a;
  padding: 3px;
  margin: 3px 0;
}

.container-tasca:hover {
  background-color: #8181822a;
}

.container-tasca i {
  cursor: pointer;
}

.titol-tasca{
  width: 70%;
}

.estat-tasca{
  width: 120px;
  padding:3px;
  text-align: center;
  border-radius:5px;
}
</style>
