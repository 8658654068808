<template>
  <div
    class="d-autenticacio align-items-stretch"
    style="background-image: url('https://picsum.photos/2560/1440')"
  >
    <div class="d-login">
      <div class="login-card">
        <div class="text-center login-logo">
          <img src="../../assets/img/lluert_logo.png" alt="logo" />
        </div>
        <div class="">
          <p class="text-center">Introdueix les teves dades</p>

          <form action class="form" @submit.prevent="loginSubmit">
            <label class="form-label" for="#email">
              <i class="bi bi-person-fill me-1"></i>Login</label
            >
            <input
              v-model="username"
              class="form-input"
              type="text"
              id="email"
              required
              placeholder="Email"
            />
            <label class="form-label" for="#password"
              ><i class="bi bi-lock-fill me-1"></i>Password:</label
            >
            <input
              v-model="password"
              class="form-input"
              type="password"
              id="password"
              placeholder="Password"
            />
            <p class="error mt-2">
              {{ authUserStore.getError }}
            </p>
            <input class="input-submit" type="submit" value="Login" />
          </form>
        </div>
      </div>
    </div>
    <div class="d-credits">
      <div>
        <span
          ><a target="_blank" href="//lluert.net"
            ><img src="../../assets/img/lluert_white.png" /></a
        ></span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref} from "vue";
import { useAuthUserStore } from "@/store/authUser";


const username = ref("");
const password = ref("");
const authUserStore = useAuthUserStore();

const loginSubmit = async () => {
  authUserStore.login(username.value, password.value);
};


</script>

<style scoped>
.d-autenticacio {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index:10000;
}

.form {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  min-width: 375px;
  max-width: 100%;
  border-radius: 5px;
}
.form-label {
  margin-top: 2rem;
  color: black;
  margin-bottom: 0.5rem;
}
.form-input {
  padding: 5px 15px;
  background: #e8f0fe;
  background-image: none;
  border: 1px solid white;
  border-radius: 5px;
}

.input-submit {
  background-color: #95c11e !important;
  margin-bottom: 30px;
  padding: 5px 15px;
  border: none;
  color: white !important;
  border-radius: 5px;
}

.input-submit:hover {
  background-color: #84ab1b !important;
}

.error {
  color: red;
}
</style>
