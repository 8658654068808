<template>
<div id="llistat">
  <div class="navbar" style="padding: 0">
    <div class="barraSuperior">
      <div class="titolApartat">
        <h1>Prova client "{{dadesClientFiltrat().nomClient}}"</h1>
      </div>
      <div class="navbar navbar-expand-lg menuVistes">
        <ul class="nav justify-content-start">
        
            <li class="nav-item"><a class="nav-link noDisabled" :class="{active: isActive(Dades)}" @click="handleLayout(Dades)"><span>Client</span></a></li>
            <li class="nav-item"><a class="nav-link noDisabled" :class="{active: isActive(Intercanvi)}" @click="handleLayout(Intercanvi)"><span>Intercanvi documents</span></a></li>
        
        </ul>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <component :is="viewComponent" />
  </div>
  </div>
</template>

<script setup>

import { defineAsyncComponent, shallowRef } from "vue";
import { useRoute } from "vue-router";
import { useClientsStore } from "../store/useClients"


//------COMPONENTS DINÀMICS------

const Dades = defineAsyncComponent(() => import("../views/DadesClientView.vue"));

const Intercanvi = defineAsyncComponent(() => import("../views/IntercanviDocumentsView.vue"));

let viewComponent = shallowRef(Dades);

const clientStore = useClientsStore();

const route = useRoute();
clientStore.loadClientBasic(route.params.id);
 
// FUNCIÓ PER ASSIGNAR EL VALOR DEL COMPONENT

const handleLayout = (component) => {
  viewComponent.value = component;

};

// FUNCIÓ PER AFEGIR CLASSE ACTIVE AL MENÚ VISTES

const isActive = (component) => {
  if(viewComponent.value === component){
    return true
  }else{
    return false
  }
}



// FUNCIÓ PER FILTRAR LES DADES DE CLIENT QUE EM RETORNA EL JSON

const dadesClientFiltrat = () => {
  if (clientStore.clientData[0].dadesClient) {
    return clientStore.clientData[0].dadesClient;
  } else {
    return false;
  }
};

</script>

<style scoped>
span{
  color:#37474f;
}
</style>

