<template>
 <div class="card btGruiSucces ">
  <div class="space-y-2">
    <VDatePicker
      title-position="left" 
      locale="ca"
      v-model="range"
      is-range
      mode="data"
      :select-attribute="selectAttribute"
      expanded
      @dayclick="getTasques"
    />
  </div>
  </div>
</template>

<script setup>
import { useTasquesStore } from "../store/useTasques";
import { ref } from "vue";

const useStore = useTasquesStore();

const range = ref({
  start: new Date(),
  end: new Date(),
});

const selectAttribute = ref({ dot: true });

const getTasques = () => {

  useStore.dataInici = range.value.start;
  useStore.dataFi = range.value.end;

  //PINTA LES TASQUES REALITZACIÓ
  useStore.fetchRealitzacions()

  //ACTUALITZA LES DADES DE FULL CALENDAR
  useStore.actualitzarTasquesFullCalendar()
};
</script>

<style>
.vc-nav-container button,
.vc-header button {
  background-color: transparent;
}
</style>
