<template>
<div id="llistat">
    <div class="navbar" style="padding: 0">
      <div class="barraSuperior">
        <div class="titolApartat">
          <h1>Fitxar</h1>
        </div>
        <div class="navbar navbar-expand-lg menuVistes"></div>
      </div>
    </div>

    <div class="container-fluid">
  
    </div>
</div>

</template>

<script></script>

<style scoped></style>