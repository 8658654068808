import { defineStore } from "pinia";
import { apiFech } from "@/composables/apiFetch";

const baseUrl = process.env.VUE_APP_API_URL_BASE + `/users/`;

export const useUsersStore = defineStore({
  id: "users",
  state: () => ({
    users: [],
    userData: [],
    error: [],
    status: 0, //0 res, 1 finalitzat , 2 loading, 3 error

    statusUserInfo: 0, //0 res, 1 finalitzat , 2 loading, 3 error
    filter: {},
    order: { nomUsuari: "ASC" },
  }),

  getters: {
    getUsers(state) {
      return state.users;
    },

    isLoading(state) {
      return state.status == 2 ? true : false;
    },
    isError(state) {
      return state.status == 3 ? true : false;
    },
    isLoadingUserInfo(state) {
      return state.statusUserInfo == 2 || state.statusUserInfo == 0
        ? true
        : false;
    },
    isErrorUserInfo(state) {
      return state.statusUserInfo == 3 ? true : false;
    },
  },

  actions: {
    
    //RETORNA JSON DE USUARIS

    async fetchUsers() {
      this.status = 2;

      const user = await apiFech.get(
        `${baseUrl}` +
          "?order=" +
          JSON.stringify(this.order) +
          "&filter=" +
          JSON.stringify(this.filter)
      );

      if (user.status == "ok" && user.result) {
        this.users = user.result;
        this.status = 1;
      } else {
        this.error = user.error;
        this.status = 3;
        return false;
      }
    },

    //FUNCIO QUE REP ORDRE I HO ASSIGNA A L'ESTAT

    addOrder(campOrdre) {
      this.order = campOrdre;
    },

    //FUNCIO QUE REP FILTRE I HO ASSIGNA A L'ESTAT

    addFilter(campFilter) {
      this.filter = campFilter;
    },

    // RETORNA DADES BÀSIQUES DE USUARI

    async fetchUserDataBasic() {
      this.status = 2;
      const fetchTMP = await apiFech.get(
        `${baseUrl}/${this.userData.idUsuari}`
      );

      if (fetchTMP.status == "ok" && fetchTMP.result) {
        this.userData = fetchTMP.result;
        this.status = 1;
      } else {
        this.error = fetchTMP.error;
        this.status = 3;
        return false;
      }
    },

    // RETORNA DADES AMPLIADES DE USUARI

    async fetchUserDataInfo() {
      if (this.userData.idUsuari) {
        this.statusUserInfo = 2;

        const fetchTMP2 = await apiFech.get(
          baseUrl + "/info/" + this.userData.idUsuari
        );

        if (fetchTMP2.status == "ok" && fetchTMP2.result) {
          this.userData = fetchTMP2.result;

          this.statusUserInfo = 1;
        } else {
          this.errror = fetchTMP2.error;
          this.statusUserInfo = 3;
          return false;
        }
      }
    },

    //RECUPERA LES DADES BÀSIQUES DE USUARI

    loadUserBasic(userId) {
      this.status = 2;
      this.userData.idUsuari = userId;
      this.fetchUserDataBasic();
    },

    //RECUPERA LES DADES AMPLIDES DE USUARI

    loadUserInfo(id) {
      this.statusUserInfo = 0;
      this.userData.idUsuari = id;
      this.fetchUserDataInfo();
    },

    //ACTUALITZA DADES DE USUARI

    async updateUserData() {
      const dataToPost = {
        nomUsuari: this.userData.nomUsuari,
        cognomUsuari: this.userData.cognomUsuari,
      };

      const fetchTMP3 = await apiFech.post(
        baseUrl + "/update/" + this.userData.idUsuari,
        dataToPost
      );

      if (fetchTMP3.status == "ok" && fetchTMP3.result) {
        //this.fetchUserDataInfo();
        location.reload();
      } else {
        this.errror = fetchTMP3.error;
        this.statusUserInfo = 3;
        return false;
      }
    },

    
  },
});
