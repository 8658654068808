<template>
  <div id="llistat" style="padding-top: 50px">
    <div class="titol-pagina">
      <span>Pagina Externa</span>
      <br />
      <span>{{ route.query.url }}</span>
      <br />
    </div>
  </div>
</template>

<script setup>
import { useAuthUserStore } from "@/store/authUser";
import { useRoute } from "vue-router";
import { ref, onUpdated } from "vue";

import $ from "jquery";

const authUserStore = useAuthUserStore();

// RECUPEREM VARIABLE D'ENTORN

const carrega = process.env.VUE_APP_CARREGA_PAGINA;

// INICIALITZEM VARIABLE
let urlPagina = ref(
  "SistemaBase/Operacio/operacionsLlista.php?tipusMostra=llistaEtiquetes"
);

const route = useRoute();

// FUNCIÓ PER CARREGAR LA PÀGINA RECUPERAN LA RUTA DEL NAVBAR

async function carregaPagina() {
  const requestOptions = {
    headers: { Authorization: `Bearer ${authUserStore.user.apiKey}` },
  };
  if (route.query.url) {
    urlPagina.value = route.query.url;
  }

  let response = await fetch(urlPagina.value, requestOptions);
  let resultat = await response.text();

  $("#llistat").html(resultat);
}

if (carrega === "true") {
  carregaPagina();
}

onUpdated(() => {
  if (carrega === "true") {
    carregaPagina();
  }
});
</script>

<style scoped>
.titol-pagina {
  text-align: center;
}

span {
  font-size: 20px;
}
</style>
