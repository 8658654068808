<template>
<div id="llistat">
    <div class="navbar" style="padding: 0">
      <div class="barraSuperior">
        <div class="titolApartat">
          <h1>Productes Llista</h1>
        </div>
        <div class="navbar navbar-expand-lg menuVistes"></div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="navbar menuOperacions navbar_capcalera">
      <a
        class="btn btn-outline-secondary btn-sm"
        data-bs-toggle="collapse"
        href="#collapseFilterAdvanced"
        role="button"
        aria-expanded="false"
        aria-controls="collapseFilterAdvanced"
      >
        Filtrar per camp
      </a>
      </div>
      <div class="collapse contenidor_collapse" id="collapseFilterAdvanced">
        <div class="card card-body mb-2">
          <FilterAdvanced
            :columns="gridColumns"
            :filter="filter"
            @aplyFilter="aplyFilter"
          />
        </div>
      </div>
      <div v-if="result.result">
        <LlistatTable
          :result="result.result"
          :columns="gridColumns"
          :filter="filter"
          @aplyOrder="aplyOrder"
          @aplyFilter="aplyFilter"
          @obrirFitxaId="obrirFitxaId"
        />
      </div>
      <div v-else>
        <ErrorGeneric 
          :error="productesStore.error.msgError"
        />
      </div>
      <div>
        <ProvideInject />
      </div>
    </div>
</div>
 
</template>

<script setup>

import LlistatTable from '../../components_generics/LlistatTable.vue'
import FilterAdvanced from "../../components_generics/FilterAdvanced.vue";
import ErrorGeneric from "../../components_generics/ErrorGeneric.vue"
import {useRouter} from 'vue-router'
import { ref } from 'vue';



// PROVA DE PROVIDE/INJECT

import { provide } from 'vue'
import ProvideInject from '../components/ProvideInject.vue';
const missatge = ref("missatge desde provide")

const actualitzarMissatge = (msg) => {
  missatge.value = msg
  
}

provide('missatge', {missatge , actualitzarMissatge})



const router = useRouter()

const result = 
  {
   "status":"ok",
   "result":[
    {
      'id' : 1,
      'referenciaProducte': '43456',
      'nomProducte' : 'Producte 1',
      'stockDisponible' : '2',
      'tipusIvaProducte' : '21%',
      'preuProducte' : '450.00',

   },
  {
    'id' : 2,
    'referenciaProducte': '12345',
    'nomProducte' : 'Producte 2',
    'stockDisponible' : '23',
    'tipusIvaProducte' : '21%',
    'preuProducte' : '120:00',

   },
    {
    'id' : 3,
    'referenciaProducte': '12345',
    'nomProducte' : 'Producte 3',
    'stockDisponible' : '42',
    'tipusIvaProducte' : '21%',
    'preuProducte' : '920.00',

   }, 
   {
    'id' : 4,
    'referenciaProducte': '33245',
    'nomProducte' : 'Producte 4',
    'stockDisponible' : '11',
    'tipusIvaProducte' : '4%',
    'preuProducte' : '23.00',

   }, 
    ]
    }


let filter = { actiuUsuari: 1 };

const gridColumns = [
  {
    name: "Referència",
    key: "referenciaProducte",
    type: "text",
  },
  {
    name: "Nom producte",
    key: "nomProducte",
    type: "text",
  },
  {
    name: "Stock disponible",
    key: "stockDisponible",
    type: "",
  },
   {
    name: "Tipus Iva",
    key: "tipusIvaProducte",
    type: "text",
  },
   {
    name: "Preu producte",
    key: "preuProducte",
    type: "text",
  },

];

//FUNCIÓ PER RECUPERAR ORDRE Y PASSAR-HO A LA FUNCIÓ DEL STORE (NO UTILITZEM)

const aplyOrder = (campOrdre, order) => {
  let objecteOrder = {}
  objecteOrder[campOrdre] = order ;

};

//FUNCIÓ PER RECUPERAR FILTRE Y PASSAR-HO A LA FUNCIÓ DEL STORE (NO UTILITZEM)

const aplyFilter = (filterTMP) => {
  filter = filterTMP;
};

//FUNCIÓ PER OBRIR LA FITXA DEL PRODUCTE

const obrirFitxaId = (id) => {
   router.push({name: 'producteFitxa', params: { id:id } })
}

</script>

<style scoped>

</style>