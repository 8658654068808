import { defineStore } from "pinia";
import { apiFech } from "@/composables/apiFetch";
import { formatDate } from "@/variablesGlobals/variables.js";
const baseUrl = process.env.VUE_APP_API_URL_BASE;

export const useTasquesStore = defineStore({
  id: "tasques",
  state: () => ({
    tasques: [],
    tascaData: [],
    projectes: [],
    realitzacions: [],
    tasquesProjecte: [],
    filtreProjectesIdClient: 0,
    estatTasques: "",
    filtreTascaIdProjecte: 0,
    filtreCercadorProjecte: "",
    filtrePrioritatTasca: "0",
    nomProjecte: "",
    veureProjecte: true,
    fitxatges: [],
    estatFitxatges: [],
    error: [],
    status: 0,
    statusTascaInfo: 0,
    filter: {},
    order: { nomProducte: "ASC" },
    dataInici: new Date(),
    dataFi: new Date(),
    clauInfoTasquesRealitzacioCalendar: 0,
    mostrarCronometreTasca : false,
    titolTascaCronometre : '',
    cronometrar : false,
  
  }), 

  getters: {
    getTasques(state) {
      return state.tasques;
    },
    isLoading(state) {
      return state.status == 2 ? true : false;
    },
    isError(state) {
      return state.status == 3 ? true : false;
    },
    isLoadingTascaInfo(state) {
      return state.statusTascaInfo == 2 || state.statusTascaInfo == 0
        ? true
        : false;
    },
    isErrorTascaInfo(state) {
      return state.statusTascaInfo == 3 ? true : false;
    },
  },

  actions: {
  
    //RETORNA JSON DE TASQUES DE PROJECTES

    async fetchTasques() {
      this.status = 2;
      const tasca = await apiFech.get(`${baseUrl}` + "/tasques");
      if (tasca.resultat) {
        this.tasques = tasca.resultat;
        this.status = 1;
      } else {
        this.error = tasca.error;
        this.status = 3;
        return false;
      }
    },

    //RETORNA JSON DE PROJECTES

    async fetchProjectes() {
      this.status = 2;
      const projectes = await apiFech.get(`${baseUrl}` + "/projectes");
      if (projectes.status == "ok" && projectes.result) {
        this.projectes = projectes.result;
        localStorage.setItem("projectes", JSON.stringify(this.projectes));
        this.status = 1;
      } else {
        this.error = projectes.error;
        this.status = 3;
        return false;
      }
    },

    //RETORNA JSON DE TASQUES REALITZACIÓ

    async fetchRealitzacions() {
      this.status = 2;
      const realitzacions = await apiFech.get(
        `${baseUrl}` +
          "/Tasques/Realitzacio/" +
          "?dataInici=" +
          formatDate(this.dataInici) +
          "&dataFi=" +
          formatDate(this.dataFi)
      );
      this.realitzacions = realitzacions;
    },

    
    //INICIA LA TASCA POSANT HORA INICI I HORA FI

    async fetchStarStop(id) {
      const dataToPost = {
        idTasca: id,
      };
      const stopAndPlay = await apiFech.post(baseUrl + "/Tasques/startStopTasca/", dataToPost);
      if (stopAndPlay.status == "ok" && stopAndPlay.result) {
        this.fetchRealitzacions();
      } else {
        this.errror = stopAndPlay.error;
        this.statusTascaInfo = 3;
        return false;
      }
    },

    // AFEGIM COMENTARI

    async postAfegirComentari(id , comentari) {
      const dataToPost = {
        id: id,
        comentaris: comentari,
      };
      const comentaris = await apiFech.post(baseUrl + "/assignarComentari/", dataToPost);
      if (comentaris.status == "ok" && comentaris.result) {
        this.fetchRealitzacions();
       
      } else {
        this.errror = comentaris.error;
        this.statusTascaInfo = 3;
        return false;
      }
    },

    //FUNCIÓ PER ACTUALITZAR LA SELECCIÓ DE DIES DE VISUALITZACIÓ DE FULL CALENDAR

    actualitzarTasquesFullCalendar(){
      this.clauInfoTasquesRealitzacioCalendar += 1
    },

    //FUNCIÓ PER GUARDAR A STATE EL VALOR DEL PARAMETRE

    filtrePrioritat(prioritat) {
      this.filtrePrioritatTasca = prioritat;
    },

    //FUNCIÓ PER GUARDAR A STATE EL VALOR DEL PARAMETRE

    filtreClient(idClient) {
      this.filtreProjectesIdClient = idClient;
    },

    //FUNCIÓ PER GUARDAR A STATE EL VALOR DEL PARAMETREM I INICIALITZAR A 0 FILTRETASCAIDPROJECTE

    fitreProjecte(idProjecte, nomProjecte) {
      this.filtreTascaIdProjecte = 0
      this.nomProjecte = nomProjecte;
      this.filtreTascaIdProjecte = idProjecte;

    },

    //FUNCIÓ PER GUARDAR A STATE EL VALOR DEL PARAMETRE

    filtreInputProjecte(cercadorProjecte) {
      this.filtreCercadorProjecte = cercadorProjecte;
    },

     //FUNCIÓ PER GUARDAR A STATE EL VALOR DEL PARAMETRE

    filtreEstat(estat) {
      this.estatTasques = estat;
    },

    //FUNCIÓ PER INIZIALITZAR TOS EL VALORS DE LES VARIABLES D'ESTAT DE PROJECTES

    inicialitzaProjectes() {
      this.filtreProjectesIdClient = 0;
      this.filtreTascaIdProjecte = 0;
      this.estatTasques = "";
      this.filtreCercadorProjecte = "";
      this.nomProjecte = "";

    },


    //RETORNA LLISTAT DE PROJECTES FILTRATS

    llistatProjectesFiltrat() {
      let resultTmp = this.projectes;
      if (this.filtreProjectesIdClient !== 0) {
        resultTmp = resultTmp.filter(
          (projecte) => projecte.idClient === this.filtreProjectesIdClient
        );
      }

      if (this.filtreCercadorProjecte !== "") {
        resultTmp = resultTmp.filter(
          (item) =>
            item.alias
              .toLowerCase()
              .match(this.filtreCercadorProjecte.toLowerCase()) 
              ||  item.nomClient
              .toLowerCase()
              .match(this.filtreCercadorProjecte.toLowerCase())
        );
      }
      return resultTmp;
    },

    //RETORNA LLISTAT DE TASQUES FILTRAT

    llistatTasquesFiltrat() {
      let resultTmp = this.tasques;
      if (this.filtreTascaIdProjecte !== 0) {
        resultTmp = resultTmp.filter(
          (tasca) => tasca.idProjecteTasca === this.filtreTascaIdProjecte
        );
      }

      if (this.estatTasques !== "") {
        resultTmp = resultTmp.filter(
          (estat) => estat.nomEstatTasca === this.estatTasques
        );
      }

      if (this.filtrePrioritatTasca !== '*') {
        resultTmp = resultTmp.filter(
          (prioritat) => prioritat.prioritatTasca === this.filtrePrioritatTasca
        );
      }

      return resultTmp;
    },

  },
});
