<template>
  <div>
    {{ missatge }}
    <input id="missatge" type="text" v-model="msg"  />
    <button class="btn btn-sm btn-primary" @click="actualitzarMissatgeNetejarInput(msg)">
      Actualitzar missatge
    </button>
  </div>
</template>

<script setup>
import { inject } from "vue";

const { missatge, actualitzarMissatge } = inject("missatge");

const actualitzarMissatgeNetejarInput = (msg) => {
  actualitzarMissatge(msg);
};
</script>
