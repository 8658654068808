<template>
  <div id="llistat">
    <div class="navbar" style="padding: 0">
      <div class="barraSuperior">
        <div class="titolApartat">
          <h1>Llistat comandes client</h1>
        </div>
        <div class="navbar navbar-expand-lg menuVistes"></div>
      </div>
    </div>

    <div class="container-fluid">
         <div class="navbar menuOperacions navbar_capcalera">
      <a
        class="btn btn-outline-secondary btn-sm"
        data-bs-toggle="collapse"
        href="#collapseFilterAdvanced"
        role="button"
        aria-expanded="false"
        aria-controls="collapseFilterAdvanced"
      >
        Filtrar per camp
      </a>
    </div>
    <div class="collapse contenidor_collapse" id="collapseFilterAdvanced">
      <div class="card card-body mb-2">
        <FilterAdvanced
          :columns="gridColumns"
          :filter="filter"
          @aplyFilter="aplyFilter"
        />
      </div>
    </div>

      <LlistatTable
        :result="result"
        :columns="gridColumns"
        :filter="filter"
        @aplyOrder="aplyOrder"
        @aplyFilter="aplyFilter"
        @obrirFitxaId="obrirFitxaId"
      />
    </div>
    </div>
 
</template>

<script setup>
import LlistatTable from '../../components_generics/LlistatTable.vue'
import {useRouter} from 'vue-router'
import FilterAdvanced from "../../components_generics/FilterAdvanced.vue";

import { ref,  } from "vue";

const router = useRouter()
const result = ref([
  {
  id:"0",
  numComanda: 1,
  nomComanda: 'Client 1',
  dataComanda: '27/07/2023',
  estatComanda: 'Pendent',
  preuComanda: 339.40,
  },
  {
  id:"1",
  numComanda: 2,
  nomComanda: 'Client 2',
  dataComanda: '27/07/2023',
  estatComanda: 'Pendent',
  preuComanda: 243.40,
  }
]);
const orderBy = ref("");
let filter = { actiuUsuari: 1 };

const gridColumns = [
  {
    name: "Num",
    key: "numComanda",
    type: "text",
  },
  {
    name: "Nom",
    key: "nomComanda",
    type: "select",
       options: [
      {
        name: "Tots",
        value: "*",
      },
      {
        name: "Client 1",
        value: 1,
      },
      {
        name: "Client 2",
        value: 0,
      },
    ],
  },
  {
    name: "Data comanda",
    key: "dataComanda",
    type: "text",
  },
  {
    name: "Estat comanda",
    key: "estatComanda",
    type: "select",
       options: [
      {
        name: "Tots",
        value: "*",
      },
      {
        name: "Pendent",
        value: 1,
      },
      {
        name: "Lliurada",
        value: 2,
      },
      {
        name: "Lliurada parcial",
        value: 3,
      },
    ],
    
  },
  {
    name: "Preu total",
    key: "preuComanda",
    type: "text",
 
  },



];


//FUNCIÓ PER APLICAR ORDRE (NO UTILITZEM)

const aplyOrder = (campOrdre, order) => {
  orderBy.value = `${campOrdre} ${order}`;
};

//FUNCIÓ PER APLICAR FILTRE (NO UTILITZEM)

const aplyFilter = (filterTMP) => {
  filter = filterTMP;
};

//FUNCIÓ PER OBRIR FITXA DE FACTURA CLIENT

const obrirFitxaId = (id) => {
   router.push({name: 'comandaFitxa', params: { id:id } })
}

</script>

<style scoped></style>