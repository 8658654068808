<template>
    <div class="d-flex ">
		<a class="noDisabled d-flex align-items-center ml dropdown-toggle" data-bs-toggle="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="perfil nouredinne">
			<span v-if="authUserStore.user" class="mr d-none  d-md-block">{{authUserStore.user.name}}</span>

			<div><span class="bi bi-circle-fill me-1" id="btn_online_<?= $usuariSessio->get('idUsuari') ?>"></span></div>
			<img class="rounded mx-auto d-block me-2" width="40" height="40" style="border-radius:50%;" alt="User Image" src="../assets/img/lluert_icon.png" />
		</a>
		<div class="dropdown-menu dropdown-menu-right">
			<div class="dropdown-header text-center">
				<img src="../assets/img/lluert_icon.png" class="rounded mx-auto d-block" width="100" alt="User Image">
				<em v-if="authUserStore.user" class="font-weight-bold">{{authUserStore.user.name}}</em>
			</div>
			<div class="dropdown-divider"></div>
			
				<a class="noDisabled dropdown-item" @click="obreFitxa(authUserStore.user.idUsuari)" style="z-index: -1;"><i class="bi bi-person-fill me-1"></i>Perfil</a>
		
				<a class="noDisabled dropdown-item" @click="authUserStore.logout()" href="#" style="z-index: -1;"><i class="bi bi-x-lg me-1"></i>tancar sessió</a>
		</div>
	</div>
</template>


<script setup>
import { useAuthUserStore } from "../store/authUser";
import {useRouter} from 'vue-router'



const router = useRouter()

const authUserStore = useAuthUserStore();


const obreFitxa = (id) => {
  router.push({name: 'usuarisFitxa', params: { id:id } })
        
}

</script>