<template>
  <div id="llistat">
    <div class="navbar" style="padding: 0">
      <div class="barraSuperior">
        <div class="titolApartat">
          <h1>Llistat albarans client</h1>
        </div>
        <div class="navbar navbar-expand-lg menuVistes"></div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="navbar menuOperacions navbar_capcalera">
        <a
          class="btn btn-outline-secondary btn-sm"
          data-bs-toggle="collapse"
          href="#collapseFilterAdvanced"
          role="button"
          aria-expanded="false"
          aria-controls="collapseFilterAdvanced"
        >
          Filtrar per camp
        </a>
      </div>
      <div class="collapse contenidor_collapse" id="collapseFilterAdvanced">
        <div class="card card-body mb-2">
          <FilterAdvanced
            :columns="gridColumns"
            :filter="filter"
            @aplyFilter="aplyFilter"
          />
        </div>
      </div>

      <LlistatTable
        :result="result"
        :columns="gridColumns"
        :filter="filter"
        @aplyOrder="aplyOrder"
        @aplyFilter="aplyFilter"
        @obrirFitxaId="obrirFitxaId"
      />
    </div>
  </div>
</template>

<script setup>
import LlistatTable from "../../components_generics/LlistatTable.vue";
import { useRouter } from "vue-router";
import { useUsersStore } from "../../usuaris/store/useUsuaris";
import FilterAdvanced from "../../components_generics/FilterAdvanced.vue";

import { ref} from "vue";

const userStore = useUsersStore();
const router = useRouter();
const result = ref([
  {
    id: "0",
    numAlbara: "1",
    nomClient: "Client 1",
    dataAlbara: "27/07/2023",
    estatAlbara: "Pendent",
    preuTotal: 339.4,
  },
  {
    id: "1",
    numAlbara: "2",
    nomClient: "Client 2",
    dataAlbara: "27/07/2023",
    estatAlbara: "Pendent",
    preuTotal: 243.4,
  },
]);


const orderBy = ref("");


let filter = { actiuUsuari: 1 };


const gridColumns = [
  {
    name: "Num",
    key: "numAlbara",
    type: "text",
  },
  {
    name: "Nom",
    key: "nomClient",
    type: "select",
    options: [
      {
        name: "Tots",
        value: "*",
      },
      {
        name: "Client 1",
        value: 1,
      },
      {
        name: "Client 2",
        value: 0,
      },
    ],
  },
  {
    name: "Data albara",
    key: "dataAlbara",
    type: "text",
  },
  {
    name: "Estat albara",
    key: "estatAlbara",
    type: "select",
    options: [
      {
        name: "Tots",
        value: "*",
      },
      {
        name: "Pendent",
        value: 1,
      },
      {
        name: "Lliurada",
        value: 2,
      },
      {
        name: "Lliurada parcial",
        value: 3,
      },
    ],
  },
  {
    name: "Preu total",
    key: "preuTotal",
    type: "text",
  },
];

  userStore.fetchUsers();


//FUNCIÓ PER APLICAR ORDRE (NO UTILITZEM)

const aplyOrder = (campOrdre, order) => {
  orderBy.value = `${campOrdre} ${order}`;
};

//FUNCIÓ PER APLICAR FLITRE (NO UTILITZEM)

const aplyFilter = (filterTMP) => {
  filter = filterTMP;
};

//FUNCIÓ PER OBRIR FITXA DE ALBARÀ CLIENT

const obrirFitxaId = (id) => {
  router.push({ name: "albaraFitxa", params: { id: id } });
};


</script>

<style scoped></style>
