<template>
  <div class="card btGruiSucces mb-1">
    <div class="card-header">Llista Projectes</div>
    <div class="card-body" id="tasca_collapse">
      <div class="input-group mb-3">
        <input
          type="text"
          id="input-tasques"
          class="form-control"
          v-model="cercadorProjecte"
          @keyup="useStore.filtreInputProjecte(cercadorProjecte)"
          placeholder="cerca..."
        />
        <button
          class="btn btn-outline-secondary btn-sm"
          @click="inicialitzaLlistaProjectes"
        >
          <i class="bi bi-arrow-clockwise"></i>
        </button>
      </div>
      <div 
        v-for="projecte in useStore.llistatProjectesFiltrat()"
        :key="projecte.id"
      >
          <div
            class="container-client"
            data-bs-toggle="collapse"
            :href="`#${projecte.id}`"
            :style="{ 'border-left-color': projecte.color }"
            @click="useStore.fitreProjecte(projecte.id, projecte.alias)"
          >
            <div class="info-tasca">
              <div >
                <span class="projecte">{{ projecte.alias }}</span>
              </div>
              <div>
                <span class="client">{{ projecte.nomClient }}</span>
              </div>
            </div>
            <div class="imatge-client">
              <span>imatge</span>
            </div>
            <div>
              <i class="bi bi-arrow-bar-down me-3" style="color: #222"></i>
            </div>
          </div>
          <div class="collapse " :id="projecte.id" data-bs-parent="#tasca_collapse" >
              <InfoProjectesDrop />
          </div>
       
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useTasquesStore } from "../store/useTasques";

import InfoProjectesDrop from "../components/InfoProjectesDrop.vue";

const useStore = useTasquesStore();

const cercadorProjecte = ref("");

//FUNCIÓ PER ANALITZAR LLISTA DE PROJECTES I NETEJAR INPUT DE BUSCADOR

const inicialitzaLlistaProjectes = () => {
  useStore.inicialitzaProjectes();
  cercadorProjecte.value = "";
};
</script>

<style scoped>
.container-client {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-left: 8px transparent solid;
  border-top: 1px solid #8181823a;
  border-bottom: 1px solid #8181823a;
  border-right: 1px solid #8181823a;
  padding: 3px;
  margin: 3px 0;
}

.container-client:hover {
  cursor: pointer;
  background-color: #8181822a;
}

.info-tasca {
  min-width: 60%;

}

.projecte {
  font-weight: bold;
}
.client {
  font-weight: 300;
  font-size: 0.8rem;
}

.imatge-client {
  width: 20%;
}

@media (max-width: 768px) {

 .imatge-client {
  display: none;
 }
  
}
</style>
>
