<template>
  <div id="llistat">
    <div class="navbar" style="padding: 0">
      <div class="barraSuperior">
        <div class="titolApartat">
          <h1>Clients Llista</h1>
        </div>
        <div class="navbar navbar-expand-lg menuVistes"></div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="navbar menuOperacions navbar_capcalera">
        <a
          class="btn btn-outline-secondary btn-sm"
          data-bs-toggle="collapse"
          href="#collapseFilterAdvanced"
          role="button"
          aria-expanded="false"
          aria-controls="collapseFilterAdvanced"
        >
          Filtrar per camp
        </a>
      </div>
      <div class="collapse contenidor_collapse" id="collapseFilterAdvanced">
        <div class="card card-body mb-2">
          <FilterAdvanced
            :columns="gridColumns"
            :filter="filter"
            @aplyFilter="aplyFilter"
          />
        </div>
      </div>
      <div v-if="dadesClientFiltrat()">
        <LlistatTable
          :result="dadesClientFiltrat()"
          :columns="gridColumns"
          :filter="filter"
          @aplyOrder="aplyOrder"
          @aplyFilter="aplyFilter"
          @obrirFitxaId="obrirFitxaId"
        />
      </div>
      <div v-else>
        <ErrorGeneric :error="clientStore.error.msgError" />
      </div>
    </div>
  </div>
</template>

<script setup>
import LlistatTable from "../../components_generics/LlistatTable.vue";
import FilterAdvanced from "../../components_generics/FilterAdvanced.vue";
import ErrorGeneric from "../../components_generics/ErrorGeneric.vue";
import { useClientsStore } from "../../clients/store/useClients";
import { useRouter } from "vue-router";


//VARIABLES

const clientStore = useClientsStore();
const router = useRouter();

clientStore.fetchClients();


let filter = { actiuUsuari: 1 };

const gridColumns = [
  {
    name: "ID",
    key: "idClient",
    type: "text",
  },
  {
    name: "Nom client",
    key: "nomClient",
    type: "text",
  },
  {
    name: "Email",
    key: "emailClient",
    type: "text",
  },
];





//FUNCIÓ PER RETORNAR ARRAY DE DADES DEL CLIENT

const dadesClientFiltrat = () => {
  if (clientStore.clients) {
    return clientStore.clients.map((dada) => dada.dadesClient);
  } else {
    return false;
  }
};

//FUNCIÓ PER RECUPERAR ORDRE Y PASSAR-HO A LA FUNCIÓ DEL STORE

const aplyOrder = (campOrdre, order) => {
  let objecteOrder = {};
  objecteOrder[campOrdre] = order;
  clientStore.addOrder(objecteOrder);
  clientStore.fetchClients();
};

//FUNCIÓ PER RECUPERAR FILTRE Y PASSAR-HO A LA FUNCIÓ DEL STORE

const aplyFilter = (filterTMP) => {
  filter = filterTMP;
  clientStore.addFilter(filter);
  clientStore.fetchClients();
};

//FUNCIÓ PER OBRIR LA FITXA DEL CLIENT

const obrirFitxaId = (id) => {
  router.push({ name: "clientFitxa", params: { id: id } });
};
</script>

<style scoped></style>
