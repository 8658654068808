
<template>
<div id="button_menuFlotant">
   <a class="btn btn-outline-secondary " @click="mostrarMenu" href="#">
      <i class="bi bi-list"></i>
   </a>
</div>
    <nav id="sideMenu" v-show="authUserStore.isLoggedIn()"> 
        <div id="sideMenuHead">
        <figure style="text-align: center;">
            <img src="../../src/assets/img/lluert_white.png" alt="logo">
        </figure>
        <div id="botonLogo" style="float:right;">
            <a class="btn noDisabled " @click="amagarMenu">
                <i class="bi bi-chevron-double-left text-light"></i>
            </a>
        </div>
        </div>

        <div id="sideMenuBody">
            <ul>
                
                <li class="nav-item ">
                    <router-link :to="{name: 'homeView'}" class="noDisabled" style="background-color: transparent;" href="">
                    <i class="bi bi-house-fill me-1"></i>
                    <span>Inici Vue</span>

                    </router-link> 
                </li>
                <li class="nav-item menu ">
                    <router-link :to="{name: 'fitxar'}">
                    <i class="bi bi-clock-fill me-1"></i>
                    <span>Fitxar Vue</span>
                    </router-link> 
                </li>
                
                <li class="nav-item menu">
                    <a  class="noDisabled fletxaDown collapse collapsed in"  href="#menu_configuracio" data-bs-toggle="collapse" aria-expanded="false" role="button" aria-controls="menu_configuracio" >
                        <i class="bi bi-person-fill me-1"></i>
                        <span>Configuració sistema</span>
                        <!-- <i class ="bi bi-chevron-down ms-4 "></i> -->
                    
                    </a>
                    <div class="noDisabled collapse" id="menu_configuracio"  data-bs-parent="#sideMenuBody">
                        <ul>
                            <li id="subMenu_Configuracio_Equip"><router-link class="noDisabled" :to="{name: 'usuarisllista'}"><i class="bi bi-chevron-right me-1"></i><span>Usuaris Vue</span></router-link> </li>

                            <li id="subMenu_Configuracio_Fitxatge"><router-link class="noDisabled" :to="{name: 'carregaPaginaAjax', query: {url:'SistemaBase/Usuari/usuarisLlista.php?tipusMostra=llistaFitxes&filtreActius=1'} }"><i class="bi bi-chevron-right me-1"></i><span>Equip</span></router-link> </li>
                        
                            <li id="subMenu_Configuracio_Fitxatge"><router-link class="noDisabled" :to="{name: 'carregaPaginaAjax', query: {url:'TasquesBase/TasquesFitxar/tasquesFitxarLlista.php?tipusMostra=llista'} }"><i class="bi bi-chevron-right me-1"></i><span>Fitxatges</span></router-link> </li>
                        
                            <li id="subMenu_Configuracio_Grups"><router-link class="noDisabled" :to="{name: 'carregaPaginaAjax', query: {url:'SistemaBase/Usuari/GrupsUsuaris/grupsUsuarisLlista.php?tipusMostra=llista'} }"><i class="bi bi-chevron-right me-1"></i><span>Grups</span></router-link> </li>
                        
                            <li id="subMenu_Configuracio_Operacions"><router-link class="noDisabled" :to="{name: 'carregaPaginaAjax', query: {url: 'SistemaBase/Operacio/operacionsLlista.php?tipusMostra=llistaEtiquetes'} }"><i class="bi bi-chevron-right me-1"></i><span >Operacions</span></router-link> </li>
                       
                            <li  id="subMenu_Configuracio_Perfils"><router-link class="noDisabled" :to="{name: 'carregaPaginaAjax', query: {url: 'SistemaBase/Perfil/perfilsLlista.php?tipusMostra=vistaEtiquetes'} }"><i class="bi bi-chevron-right me-1"></i><span  >Perfils</span></router-link> </li>
                        </ul>
                    </div>
                </li>


                <li class="nav-item menu">
                    <a  class="fletxaDown collapse collapsed in"  href="#menu_clients" data-bs-toggle="collapse" aria-expanded="false" role="button" aria-controls="menu_clients"  >
                        <i class="bi bi-people-fill me-1"></i>
                        <span>Clients</span>
                        <!-- <i class ="bi bi-chevron-down ms-4 "></i> -->
                    
                    </a>
                    <div class="noDisabled collapse" id="menu_clients"  data-bs-parent="#sideMenuBody">
                        <ul>
                            <li id="subMenu_Clients_Llista_vue"><router-link class="noDisabled" :to="{name: 'clientsllista'}"><i class="bi bi-chevron-right me-1"></i><span>Llistar clients Vue</span></router-link> </li>
                            <li id="subMenu_Clients_Llista"><router-link class="noDisabled" :to="{name: 'carregaPaginaAjax', query: {url: 'ClientBase/clientsLlista.php?tipusMostra=llista'} }"><i class="bi bi-chevron-right me-1"></i><span >Llista clients</span></router-link> </li>
                            <li id="subMenu_Clients_Comandes"><router-link class="noDisabled" :to="{name: 'comandesclient'}"><i class="bi bi-chevron-right me-1"></i><span>Llistar  comandes  Vue</span></router-link> </li>
                            <li id="subMenu_Clients_Albarans"><router-link class="noDisabled" :to="{name: 'albaransclient'}"><i class="bi bi-chevron-right me-1"></i><span>Llistar albarans Vue</span></router-link> </li>
                            <li id="subMenu_Clients_Factures"><router-link class="noDisabled" :to="{name: 'facturesclient'}"><i class="bi bi-chevron-right me-1"></i><span>Llistar factures Vue</span></router-link> </li>
                            <li id="subMenu_Clients_Tipus"><router-link class="noDisabled" :to="{name: 'tipusclient'}"><i class="bi bi-chevron-right me-1"></i><span>Tipus clients Vue</span></router-link> </li>
                        </ul>
                    </div>
                </li>
             

                    <li class="nav-item menu">
                    <a  class="fletxaDown collapse collapsed in"  href="#menu_productes" data-bs-toggle="collapse" aria-expanded="false" role="button" aria-controls="menu_proveidors"  >
                        <i class="bi bi-box2-fill me-1"></i>
                        <span>Productes</span>
                        <!-- <i class ="bi bi-chevron-down ms-4 "></i> -->
                    
                    </a>
                    <div class="noDisabled collapse" id="menu_productes"  data-bs-parent="#sideMenuBody">
                        <ul>
                            <li id="subMenu_Productes_Llista"><router-link class="noDisabled" :to="{name: 'productesllista'}"><i class="bi bi-chevron-right me-1"></i><span>Llistar productes Vue</span></router-link> </li>
                            
                        </ul>
                    </div>
                </li>
                  <li class="nav-item menu">
                    <a  class="fletxaDown collapse collapsed in"  href="#menu_tasques" data-bs-toggle="collapse" aria-expanded="false" role="button" aria-controls="menu_tasques"  >
                        <i class="bi bi-list-check me-1"></i>Tasques
                    </a>
                    <div class="noDisabled collapse" id="menu_tasques"  data-bs-parent="#sideMenuBody">
                        <ul>
                            <li id="subMenu_tasques_Llista"><router-link class="noDisabled" :to="{name: 'tasquesllista'}"><i class="bi bi-chevron-right me-1"></i><span>Registre tasques Vue</span></router-link> </li>
                            <li id="subMenu_Tasques"><router-link class="noDisabled" :to="{name: 'carregaPaginaAjax', query: {url: 'TasquesBase/TasquesCanvas/tasquesCanvasEstatLlista.php?tipusMostra=tasquesPrioritat'} }"><i class="bi bi-chevron-right me-1"></i><span  >Tasques</span></router-link> </li>
                            <li id="subMenu_Estat"><router-link class="noDisabled" :to="{name: 'carregaPaginaAjax', query: {url: 'TasquesBase/TasquesCanvas/tasquesCanvasEstatLlista.php?tipusMostra=tasquesCanvas'} }"><i class="bi bi-chevron-right me-1"></i><span  >Estat</span></router-link> </li>
                            <li id="subMenu_Prioritat"><router-link class="noDisabled" :to="{name: 'carregaPaginaAjax', query: {url: 'TasquesBase/TasquesPrioritats/tasquesPrioritatsLlista.php?tipusMostra=tasquesPrioritats'} }"><i class="bi bi-chevron-right me-1"></i><span  >Prioritat</span></router-link> </li>
                        </ul>
                    </div>
                </li>

                <li class="nav-item menu">
                    <a  class="fletxaDown collapse collapsed in"  href="#menu_provesLlista" data-bs-toggle="collapse" aria-expanded="false" role="button" aria-controls="menu_provesLlista"  >
                        <i class="bi bi-arrow-down-right-circle me-1"></i>
                        <span>Proves IFRAME</span>
                        <!-- <i class ="bi bi-chevron-down ms-4 "></i> -->
                    
                    </a>
                    <div class="noDisabled collapse" id="menu_provesLlista"  data-bs-parent="#sideMenuBody">
                        <ul>
                            <li id="subMenu_Proves_Llista"><router-link class="noDisabled" :to="{name: 'carregaPaginaAjax', query: {url: 'SistemaBase/Perfil/perfilsLlista.php?tipusMostra=vistaEtiquetes'} }"><i class="bi bi-chevron-right me-1"></i><span  >Perfils Llista</span></router-link> </li>
                        </ul>

                    </div>

                      <div class="noDisabled collapse" id="menu_provesLlista"  data-bs-parent="#sideMenuBody">
                        <ul>

                            <li id="subMenu_Proves_Perfil"><router-link class="noDisabled" :to="{name: 'carregaPaginaAjax', query: {url: 'SistemaBase/Operacio/operacionsLlista.php?tipusMostra=llistaEtiquetes'} }"><i class="bi bi-chevron-right me-1"></i><span >Operacions</span></router-link> </li>
                        </ul>                     
                    </div>
                </li>
                
            </ul>
        </div>
    </nav>

  </template>


  <script setup>

 import {useAuthUserStore }from '@/store/authUser'



 const authUserStore = useAuthUserStore();

const amagarMenu = () => {
    document.querySelector("body").classList.toggle("menuFlotant")
}

const mostrarMenu = () => {
    document.querySelector("body").classList.toggle("menuFlotant")
}

</script>

<style scoped >

</style>