import { defineStore } from "pinia";
import { apiFech } from "@/composables/apiFetch";
const baseUrl = process.env.VUE_APP_API_URL_BASE + `/clients`;

export const useClientsStore = defineStore({
  id: "clients",
  state: () => ({
    clients: [],
    clientData: [{ dadesClient: "", dadesFacturacio: "" }],
    error: [],
    status: 0,
    statusClientInfo: 0,
    filter: {},
    order: { nomClient: "ASC" },
  }),

  getters: {
    getClients(state) {
      return state.clients;
    },
    isLoading(state) {
      return state.status == 2 ? true : false;
    },
    isError(state) {
      return state.status == 3 ? true : false;
    },
    isLoadingClientInfo(state) {
      return state.statusClientInfo == 2 || state.statusClientInfo == 0
        ? true
        : false;
    },
    isErrorClientInfo(state) {
      return state.statusClientInfo == 3 ? true : false;
    },
  },

  actions: {
    //RETORNA JSON DE CLIENTS

    async fetchClients() {
      
      this.status = 2;
      const client = await apiFech.get(
        `${baseUrl}` +
          "/client?order=" +
          JSON.stringify(this.order) +
          "&filter=" +
          JSON.stringify(this.filter)
      );
      if (client.status == "ok" && client.result) {
        this.clients = client.result;
        //localStorage.setItem('clients' , JSON.stringify(this.clients))
        this.status = 1;
      } else {
        this.error = client.error;
        this.status = 3;
        return false;
      }
    },

    //FUNCIO QUE REP ORDRE I HO ASSIGNA A L'ESTAT

    addOrder(campOrdre) {
      this.order = campOrdre;
    },

    //FUNCIO QUE REP FILTRE I HO ASSIGNA A L'ESTAT

    addFilter(campFilter) {
      this.filter = campFilter;
    },

    // RETORNA DADES BÀSIQUES DE CLIENT

    async fetchClientDataBasic() {
      this.status = 2;
      const fetchTMP = await apiFech.get(
        `${baseUrl}/${this.clientData.idClient}`
      );
      if (fetchTMP.status == "ok" && fetchTMP.result) {
        this.clientData = fetchTMP.result;
        this.status = 1;
      } else {
        this.error = fetchTMP.error;
        this.status = 3;
        return false;
      }
    },

    // RETORNA DADES AMPLIADES DE CLIENT

    async fetchClientDataInfo() {
      if (this.clientData.idClient) {
        this.statusClientInfo = 2;

        const fetchTMP2 = await apiFech.get(
          baseUrl + "/info/" + this.clientData.idClient
        );

        if (fetchTMP2.status == "ok" && fetchTMP2.result) {
          this.clientData = fetchTMP2.result;

          this.statusClientInfo = 1;
        } else {
          this.errror = fetchTMP2.error;
          this.statusClientInfo = 3;
          return false;
        }
      }
    },

    //RECUPERA LES DADES BÀSIQUES DE CLIENT

    loadClientBasic(clientId) {
      this.status = 2;
      this.clientData.idClient = clientId;
      this.fetchClientDataBasic();
    },

    //RECUPERA LES DADES AMPLIADES DE CLIENT

    loadClientInfo(id) {
      this.statusUserInfo = 0;

      this.clientData.idClient = id;
      this.fetchClientDataBasic();
    },

     //ACTUALITZA DADES DE CLIENT

    async updateClientData(key, value) {
      const dataToPost = {
        [key]: value,
      };

      const fetchTMP3 = await apiFech.post(
        baseUrl + "/" + this.clientData[0].dadesClient.idClient,
        dataToPost
      );

      if (fetchTMP3.status == "ok" && fetchTMP3.result) {
        location.reload();
      } else {
        this.errror = fetchTMP3.error;
        this.statusClientInfo = 3;
        return false;
      }
    },
  },
});
