export const mesos = [
    "gener",
    "febrer",
    "març",
    "abril",
    "maig",
    "juny",
    "Juliol",
    "agost",
    "setembre",
    "octubre",
    "novembre",
    "desembre",
  ];


  export const formatDate = (date) => {
    let 
        month = '' + (date.getMonth() + 1),
        day = '' + date.getDate(),
        year = date.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export const convertirMinuts = (minutos) => {
    let horas = minutos / 60
    minutos = minutos % 60
    if (minutos === ""){
        return ""
    }
    else if(horas < 1){
        return `${minutos}m. `
    }else{
        return `${Math.floor(horas)}h. ${minutos}m. `
    }

    
}