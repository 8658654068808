<template>
  <div id="llistat">
    <div class="navbar" style="padding: 0">
      <div class="barraSuperior">
        <div class="titolApartat">
          <h1>Clients Llista</h1>
        </div>
        <div class="navbar navbar-expand-lg menuVistes"></div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="navbar menuOperacions navbar_capcalera">
        <a
          class="btn btn-outline-secondary btn-sm"
          data-bs-toggle="collapse"
          href="#collapseFilterAdvanced"
          role="button"
          aria-expanded="false"
          aria-controls="collapseFilterAdvanced"
        >
          Filtrar per camp
        </a>
      </div>
      <div class="collapse contenidor_collapse" id="collapseFilterAdvanced">
        <div class="card card-body mb-2">
          <FilterAdvanced
            :columns="gridColumns"
            :filter="filter"
            @aplyFilter="aplyFilter"
          />
        </div>
      </div>

      <LlistatTable
        :result="result"
        :columns="gridColumns"
        :filter="filter"
        @aplyOrder="aplyOrder"
        @aplyFilter="aplyFilter"
        @obrirFitxaId="obrirFitxaId"
      />
    </div>
  </div>
</template>

<script setup>
import LlistatTable from "../../components_generics/LlistatTable.vue";
import FilterAdvanced from "../../components_generics/FilterAdvanced.vue";

import { useRouter } from "vue-router";

import { ref } from "vue";

//VARIABLES

const router = useRouter();
const result = ref([
  {
    id: "0",
    idClient: 0,
    nomTipusClient: "Sergi",
    comentarisTipusClient: "",
    ordreTipusClient: "0",
  },
  {
    id: "1",
    idClient: 1,
    nomTipusClient: "Alex",
    comentarisTipusClient: "",
    ordreTipusClient: "0",
  },
]);
const orderBy = ref("");
let filter = { actiuUsuari: 1 };

const gridColumns = [
  {
    name: "ID",
    key: "idClient",
    type: "text",
  },
  {
    name: "Nom tipus client",
    key: "nomTipusClient",
    type: "text",
  },
  {
    name: "Comentaris tipus client",
    key: "comentarisTipusClient",
    type: "text",
  },
  {
    name: "Ordre tipus client",
    key: "ordreTipusClient",
    type: "text",
  },
];




//FUNCIÓ PER RECUPERAR ORDRE Y PASSAR-HO A LA FUNCIÓ DEL STORE (NO UTILITZEM)

const aplyOrder = (campOrdre, order) => {
  orderBy.value = `${campOrdre} ${order}`;
};

//FUNCIÓ PER RECUPERAR FILTRE Y PASSAR-HO A LA FUNCIÓ DEL STORE (NO UTILITZEM)

const aplyFilter = (filterTMP) => {
  filter = filterTMP;
};

//FUNCIÓ PER OBRIR LA FITXA DEL CLIENT

const obrirFitxaId = (id) => {
  router.push({ name: "tipusFitxa", params: { id: id } });
};
</script>

<style scoped></style>
