 <template>
    <div>
     
      <table class="table table-bordered table-striped" v-if="props.result">
        <thead>
          <tr>
            <th v-for="(column,index) in props.columns" :key="index">
              <div v-if="column.visible !== false">
                <div class="d-flex justify-content-between" >
                  <div >
                    {{column.name}}
                  </div>

                  <div v-if="column.type !== ''">
                      <a class="ml-1" href="#"><i @click="filterByOrder(column.key, 'ASC')" class="bi bi-arrow-up-short" style="color: black"></i></a>
                      <a class="ml-1" href="#"><i @click="filterByOrder(column.key, 'DESC')" class="bi bi-arrow-down-short" style="color: black"></i></a>
                  </div>

                </div>
                <input v-if="column.type ==='text'" type="text" class="form-control" v-model="filterLocal[column.key]" @blur="filterByField(filterLocal)"/>
                <input v-if="column.type ==='' " class="form-control-plaintext" readonly  />
                <select v-if="column.type === 'select'" class="form-select " v-model="filterLocal[column.key]"  @blur="filterByField(filterLocal)" >
                  <option v-for="item in column.options" :key="item" :value="item.value">{{item.name}}</option>
                </select>
              </div>
            </th>
            
          </tr>
        </thead>
        <tbody>
          <tr v-for="data in props.result" :key="data.id" @click="obriFitxa(data.id)">
            <td v-for="(column,index) in props.columns" :key="index">
              {{data[column.key]}} 
            </td>
            <td>
              <div class="text-center">
                <i class="bi bi-pencil-square" ></i>
              </div>
            
            </td>
          </tr> 
        </tbody>
      </table> 
      <p v-else>No matches found.</p> 
   
    </div>
  
</template>

<script setup>
import {defineProps , defineEmits} from 'vue'


let filterLocal = props.filter

// REBO PROPIETATS DEL PARE
const props = defineProps(['result','columns','filter'])
 

// REBO FUNCIONS DEL PARE
const emit = defineEmits(['aplyOrder','aplyFilter','obrirFitxaId'])


// PASSO LES DADES AL PARE A TRAVÉS DE EMITS

const filterByOrder = (order, campOrdre) =>{
    
    emit('aplyOrder',order,campOrdre)
} 

const filterByField = ( filter) => {
    
    emit('aplyFilter', filter)
    
}

const obriFitxa = (id) => {
  emit('obrirFitxaId', id )
}





</script>


<style scoped>
.info{
    background-color: antiquewhite;
    width: 150px;
}

</style>