import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createPinia } from 'pinia'
import { resource } from 'vue-resource'
import "bootstrap/dist/css/bootstrap.min.css"
import 'bootstrap-icons/font/bootstrap-icons.css'
import "bootstrap"

import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css'

import "./css/estils_estructura_vue.css"

const pinia = createPinia()
const app = createApp(App)
app.use(pinia)
app.use(router)
app.use(resource)
app.use(setupCalendar, {})
app.component('VCalendar', Calendar)
app.component('VDatePicker' , DatePicker)

app.mount('#app')
