<template>
<div id="llistat">
  <div class="navbar" style="padding: 0">
    <div class="barraSuperior">
      <div class="titolApartat">
        <h1>Registre tasques</h1>
      </div>
      <div class="navbar navbar-expand-lg menuVistes"></div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="navbar menuOperacions navbar_capcalera">
      <div class="dropdown">
        <a
          class="btn btn-outline-secondary dropdown-toggle"
          type="button"
          id="filtreEstat"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span>Filtre per estat:</span>
        </a>
        <ul class="dropdown-menu" aria-labelledby="filtreEstat">
          <li @click="useStore.inicialitzaProjectes()">
            <a class="dropdown-item">Tots</a>
          </li>
          <li
            v-for="(estat, index) in estatsFiltrats()"
            :key="index"
            @click="useStore.filtreEstat(estat)"
          >
            <a class="dropdown-item">{{ estat }}</a>
          </li>
        </ul>
      </div>

      <div class="dropdown">
        <a
          class="btn btn-outline-secondary dropdown-toggle"
          type="button"
          id="filtre"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i class="bi bi-share-fill"></i> Filtre
        </a>
        <ul class="dropdown-menu" aria-labelledby="filtre">
          <li v-for="(client, index) in clientsFiltrats()" :key="index">
            <a @click="useStore.filtreClient(index)" class="dropdown-item">{{
              client
            }}</a>
          </li>
        </ul>
      </div>

      <div class="dropdown">
        <a
          class="btn btn-outline-secondary dropdown-toggle"
          type="button"
          id="filtre"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i class="bi bi-person-fill"></i> Usuari
        </a>
        <ul class="dropdown-menu" aria-labelledby="filtre">
          <li class="dropdown-item">Usuari 1</li>
          <li class="dropdown-item">Usuari 2</li>
          
        </ul>
      </div>
    </div>

    <div class="row container-components">
      <div class="col-md-7 columna_esquerra">
        <div
          v-if="formatDate(useStore.dataInici) === formatDate(useStore.dataFi)"
          class="tasques-realitzacio-desktop"
        >
          <InfoTasquesRealitzacionsDiari />
        </div>
        <div v-else>
          <InfoTasquesRealitzacionsCalendar
            :key="useStore.clauInfoTasquesRealitzacioCalendar"
          />
        </div>

        
      </div>
      <div class="col-md-5 columna_dreta">
        <div class="mb-3">
          <CalendariTasques />
        </div>
        <div v-if="useStore.projectes.length > 0">
          <LlistaProjectes />
        </div>
      </div>
    </div>
  </div>
  </div>

  
</template>

<script setup>
import LlistaProjectes from "../components/LlistaProjectes.vue";
import InfoTasquesRealitzacionsCalendar from "../components/InfoTasquesRealitzacionsCalendar.vue";
import CalendariTasques from "../components/CalendariTasques.vue";
import InfoTasquesRealitzacionsDiari from "../components/InfoTasquesRealitzacioDiari.vue";
import { formatDate } from "@/variablesGlobals/variables.js";

import { useTasquesStore } from "../store/useTasques";

const useStore = useTasquesStore();
  useStore.fetchProjectes();
  useStore.fetchTasques();
  useStore.fetchRealitzacions();
  useStore.inicialitzaProjectes();

//FUNCIÓ PER FILTRAR ESTATS PEL DROPDOWN

const estatsFiltrats = () => {
  let arrayEstats = useStore.tasques.map((estat) => estat.nomEstatTasca);

  let arrayFiltratPerEstat = arrayEstats.filter(
    (estat, index) => arrayEstats.indexOf(estat) === index
  );
  return arrayFiltratPerEstat;
};

//FUNCIÓ PER FILTRAR CLIENTS PEL DROPDOWN

const clientsFiltrats = () => {
  const arrayFiltratPerClient = Object();
  useStore.projectes.map((client) => {
    if (client.idClient > 0) {
      arrayFiltratPerClient[client.idClient] = client.nomClient;
    }
  });

  return arrayFiltratPerClient;
};
</script>

<style scoped>

</style>
