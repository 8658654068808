<template>
  <div class="card btGruiSucces mb-3">
    <div class="card-body">
      <div>
        <FullCalendar 
        :options="options" />
      </div>
    </div>
  </div>
</template>



<script setup>
import { useTasquesStore } from "../store/useTasques";
import { formatDate } from "@/variablesGlobals/variables.js";
import { ref ,computed} from "vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import caLocale from "@fullcalendar/core/locales/ca";

const useStore = useTasquesStore();

//FUNCIÓ PER DETERMINAR LA VISUALITZACIÓ DEL FULL CALENDAR DEPENENT DELS DIES SELECCIONATS.

const visualitzarCalendari = () => {
  
  if(formatDate(useStore.dataInici).substring(8,10) - formatDate(useStore.dataFi).substring(8,10) <= -7  ||
      formatDate(useStore.dataFi).substring(8,10) - formatDate(useStore.dataInici).substring(8,10) >= 7){
        return "dayGridMonth"
      }

  return 'timeGrid'
}

//COMPUTEM LES REALIZACIONS PER MOSTRAR-LES TOTES, SI NO HO FEM AIXÍ NOMÉS ES MOSTREN LE ÚLTIMES.

const realitzacionsComputades = computed (() => {
  return useStore.realitzacions
})

const options = ref({
  plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
  initialView: visualitzarCalendari(),
  locale: caLocale,
  visibleRange: {
    start: useStore.dataInici,
    end: useStore.dataFi ,
  },

  businessHours: {
    startTime: "08:00",
    endTime: "20:00",
  },

  headerToolbar: {
    left: "",
    center: "title",
    right: "",
  },
  
  events: realitzacionsComputades,

});
</script>

<style scoped>

</style>
