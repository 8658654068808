<template>
  <div id="llistat">
    <div class="navbar" style="padding: 0">
      <div class="barraSuperior">
        <div class="titolApartat d-flex">
          <h1>Gestió equip</h1>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="navbar menuOperacions navbar_capcalera">
        <button
          v-if="isActive(LlistatCard)"
          class="btn btn-outline-secondary btn-sm"
          @click="vistaComponent = LlistatTable"
        >
          <i class="bi bi-list-ul"></i>
        </button>
        <button
          v-else-if="isActive(LlistatTable)"
          class="btn btn-outline-secondary btn-sm"
          @click="vistaComponent = LlistatCard"
        >
          <i class="bi bi-person-vcard"></i>
        </button>
        <a
          class="btn btn-outline-secondary btn-sm"
          data-bs-toggle="collapse"
          href="#collapseFilterAdvanced"
          role="button"
          aria-expanded="false"
          aria-controls="collapseFilterAdvanced"
        >
          Filtrar per camp
        </a>
      </div>
      <div class="collapse contenidor_collapse" id="collapseFilterAdvanced">
        <div class="card card-body mb-2">
          <FilterAdvanced
            :columns="gridColumns"
            @aplyFilter="aplyFilter"
          />
        </div>
      </div>

      <component
        :is="vistaComponent"
        :result="userStore.getUsers"
        :columns="gridColumns"
        :filter="filter"
        @aplyOrder="aplyOrder"
        @aplyFilter="aplyFilter"
        @draggableOrder="draggableOrder"
        @obrirFitxaId="obrirFitxaId"
      />
    </div>
  </div>
</template>

<script setup>
import { useUsersStore } from "../store/useUsuaris";
import FilterAdvanced from "../../components_generics/FilterAdvanced.vue";
import { useRouter } from "vue-router";

import { ref, shallowRef, defineAsyncComponent } from "vue";

//DEFINICIÓ DE COMPONENTS DINÀMICS

const LlistatCard = defineAsyncComponent(() =>
  import("../components/LlistatCard.vue")
);
const LlistatTable = defineAsyncComponent(() =>
  import("../components/LlistatTableDrop.vue")
);

//DEFINEIXO NOM DE COMPONENT I VISTA PER DEFECTE

const vistaComponent = shallowRef(LlistatCard);


const router = useRouter();
const result = ref([]);
const userStore = useUsersStore();
let filter = { actiuUsuari: "*" };

userStore.addFilter(filter);
userStore.fetchUsers();

const gridColumns = [
  {
    name: "Usuari",
    key: "nomUsuari",
    type: "text",
    visible: true,
  },
  {
    name: "Direcció",
    key: "direccioUsuari",
    type: "text",
    visible: true,
  },
  {
    name: "Email",
    key: "emailUsuari",
    type: "text",
    visible: true,
  },
  {
    name: "Actiu",
    key: "actiuUsuari",
    type: "select",
    visible: true,
    options: [
      {
        name: "Tots",
        value: "*",
      },
      {
        name: "Actiu",
        value: 1,
      },
      {
        name: "No actiu",
        value: 0,
      },
    ],
  },
];

const isActive = (cmp) => {
  if (vistaComponent.value === cmp) {
    return true;
  } else {
    return false;
  }
};

const draggableOrder = (resultat) => {
  result.value = resultat;
};
//FUNCIÓ PER RECUPERAR ORDRE Y PASSAR-HO A LA FUNCIÓ DEL STORE

const aplyOrder = (campOrdre, order) => {
  let objecteOrder = {};
  objecteOrder[campOrdre] = order;
  userStore.addOrder(objecteOrder);
  userStore.fetchUsers();
};

//FUNCIÓ PER RECUPERAR FILTRE Y PASSAR-HO A LA FUNCIÓ DEL STORE

const aplyFilter = (filterTMP) => {
  filter = filterTMP;
  console.log(filter);
  userStore.addFilter(filter);
  userStore.fetchUsers();
};

//FUNCIÓ PER OBRIR LA FITXA DE USUARI

const obrirFitxaId = (id) => {
  router.push({ name: "usuarisFitxa", params: { id: id } });
};
</script>

<style scoped>
.active {
  background-color: green !important;
}
.capcalera {
  border-bottom: 0.5px gray solid;
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  width: 100%;
  gap: 15px;
}

.container-component {
  padding: 20px;
  background-color: #f8f9fa;
  height: 100vh;
}
</style>
